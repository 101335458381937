import { Link, useLocation } from 'react-router-dom'; 
 
function Sidebar() { 
  const location = useLocation();
  const role = window.localStorage.getItem("role");
 
  return (
    <aside className="main-sidebar sidebar-light-primary elevation-4">
      {/* Brand Logo */}
       
      <Link to="/" className='brand-link'><img src={process.env.PUBLIC_URL + 'dist/img/Side-Logo.svg'} /></Link>   
        
    
      {/* Sidebar */}
      <div className="sidebar">
         
         
        
        {/* Sidebar Menu */}
        <nav className="mt-2 mb-3">
          <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
            {/* Add icons to the links using the .nav-icon class
            with font-awesome or any other icon font library */}
            <li className="nav-item "> 
              <Link to="/" className="nav-link active">
                <i className="nav-icon fas fa-tachometer-alt" />
                <p>
                  Dashboard 
                </p>
              </Link> 
            </li>
            <li className={"nav-item menu-is-opening menu-open project-menus"}>
              <a href="#" className="nav-link">
                <i className="nav-icon toggle-icon fas fa-angle-right" />
                <p>
                  Projects
                </p>
              </a>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <a  className={"nav-link " + (location.pathname.includes('/project/')  ? "active" : "")}>
                   
                    <i className="nav-icon fas fa-file-signature  fa-fw"></i>
                    <p>Contracts</p>
                  </a>
                </li>
                <li className="nav-item">
                  <a  className={"nav-link " + (location.pathname == '/contract' ? "active" : "")}>
                    <i className="nav-icon fas fa-info-circle fa-fw" /> 
                    <p>Contract Details</p>
                  </a>
                </li>
                <li className="nav-item">
                  <a  className={"nav-link " + (location.pathname == '/report' ? "active" : "")}>
                  <i className="nav-icon far fa-chart-bar fa-fw" />  
                  <p>Report / Analytics</p>
                  </a> 
                </li> 
                 
              </ul>
            </li>
            {   role === '1' ? (
              <>
            <li className="nav-line"></li>
            <li className="nav-header">Administration</li>
             

            <li className={"nav-item " + ( ["/accounts", "/add-account", "/edit-account", "/users", "/add-user", "/edit-user", "/vendors", "/add-vendor", "/edit-vendor", 
                                            "/banks", "/add-bank", "/edit-bank", "/trades", "/add-trade", "/edit-trade", "/items", "/add-item", "/edit-item"].includes(location.pathname )  ? "menu-is-opening menu-open" : "")}>
              <a href="#" className="nav-link">
                <i className="nav-icon toggle-icon fas fa-angle-right" />
                <p>Datasets</p>
              </a>
              <ul className="nav nav-treeview">
              <li className="nav-item">
                  <Link to="/users" className={"nav-link " + (location.pathname === '/users' || location.pathname === '/add-user' || location.pathname === '/edit-user' ? "active" : "")}> 
                    <i className="nav-icon fas fa-users"></i>
                    <p>List User Accounts</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/accounts" className={"nav-link " + (location.pathname === '/accounts' || location.pathname === '/add-account' || location.pathname === '/edit-account' ? "active" : "")}> 
                    <i className="nav-icon fas fa-money-check-alt fa-fw"></i>
                    <p>List Cash Accounts</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/banks" className={"nav-link " + (location.pathname === '/banks' || location.pathname === '/add-bank' || location.pathname === '/edit-bank' ? "active" : "")}> 
                    <i className="nav-icon fas fa-university fa-fw"></i>
                    <p>List Bank Accounts</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/trades" className={"nav-link " + (location.pathname === '/trades' || location.pathname === '/add-trade' || location.pathname === '/edit-trade' ? "active" : "")}> 
                    <i className="nav-icon fas fa-list fa-fw"></i>
                    <p>List Trade Items</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/items" className={"nav-link " + (location.pathname === '/items' || location.pathname === '/add-item' || location.pathname === '/edit-item' ? "active" : "")}> 
                    <i className="nav-icon fas fa-list fa-fw"></i>
                    <p>List Items</p>
                  </Link>
                </li>
                
                <li className="nav-item">
                  <Link to="/vendors" className={"nav-link " + (location.pathname === '/vendors' || location.pathname === '/add-vendor' || location.pathname === '/edit-vendor' ? "active" : "")}> 
                    <i className="nav-icon fas fa-address-book fa-fw"></i>
                    <p>List Vendors</p>
                  </Link>
                </li>
                 
              </ul>
            </li>
             
            </>
            ) : ''}
            <li className="nav-line"></li>
            <li className="nav-item">
              <Link to="/logs" className={"nav-link " + (location.pathname == '/logs' ? "active" : "")}>  
                <i className="nav-icon far fa-fw  fa-clock"></i>
                <p>Logs</p>
              </Link>
            </li>
            {   role === '1' && (
              <li className="nav-item">
              <Link to="/admin-logs" className={"nav-link " + (location.pathname == '/admin-logs' ? "active" : "")}>  
                <i className="nav-icon far fa-fw  fa-clock"></i>
                <p>Admin Logs</p>
              </Link>
            </li>
            )}
          </ul>
        </nav>
        {/* /.sidebar-menu */}
      </div>
      {/* /.sidebar */}
    </aside>

 
  );
}

export default Sidebar;
