import {  useEffect, useState } from 'react'; 
import $ from 'jquery';
import {AuthGetData, AuthPostData} from '../hook';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PageTitle from '../components/PageTitle'; 
import Loader from '../components/Loader';
import dateFormat from 'dateformat';
import { NumericFormat} from 'react-number-format';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Payment, Contract } from '../types';
import Select from 'react-select';
import { Controller, useForm } from 'react-hook-form';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import ReactPaginate from 'react-paginate';

const Project = () => {
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchStatus, setSearchStatus] = useState('');
  const [searchDate, setSearchDate] = useState<any>(''); 
  const [searchType, setSearchType] = useState('summary'); 
  const [resetFilter, setResetFilter] = useState(true);
  const [projectInfo, setProjectInfo] = useState<any>({});
  const [contracts, setContracts] = useState<Contract[]>([]) ;
  const [trades, setTrades] = useState([]) ;
  const [items, setItems] = useState([]) ;
  const [viewingPayment, setViewingPayment] = useState<Payment>();
  const [editingContractID, setEditingContractID] = useState<any>(0) ;
  const [loading, setLoading] = useState(false) ; 
  const [projectNotfound, setProjectNotfound] = useState(false) ;
  const [updatingPayment, setUpdatingPayment] = useState(false) ;
  const [responsibles, setResponsibles] = useState([]) ; 
  const { register, handleSubmit, control, setValue , formState: { errors } } = useForm(); 
  let { page,id } = useParams();
  const project_id : any = id ? id  : 0;
  const navigate = useNavigate();
  let cPage = page ? parseInt(page) : 1;
  const [paginationInfo, setPaginationInfo] = useState({pageCount: 1,})
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState<any>(null);
  const [exportRange, setExportRange] = useState('All');
  const [payables, setPayables] = useState<any>([]);
  const [receivables, setReceivables] = useState<any>([]);
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect( () => {
    AuthGetData('/project', {id: project_id, page:cPage}).then((response) => {
      if(response.success ){ 
        setProjectInfo(response.data); 
      } 
    })
    AuthGetData('/user-options', {}).then((response) => {
      if(response.success ){ 
        setResponsibles(response.data);
      }  
    }); 
    AuthGetData('/items', {}).then((response) => {
      if(response.success ){ 
        setItems(response.data);
      }  
    });  
    AuthGetData('/trades', {}).then((response) => {
      if(response.success ){ 
        setTrades(response.data);
      }  
    }); 
  },[]);
  useEffect( () => {  
      let params: any = {};
      params.project_id = project_id;
      if(searchKeyword.trim() != ''){
        params.keyword = searchKeyword.trim();
      }
      if(searchStatus!= ''){
        params.status = searchStatus;
      }
      if(!resetFilter && searchDate){
        params.date = dateFormat(searchDate, "yyyy-mm-dd");
      }
      params.type = searchType;
      params.page = cPage;
      setLoading(true); 
      AuthGetData('/project-contracts', params).then((response) => {
        if(response.success ){ 
          setContracts(response.results.data);
          setPaginationInfo({pageCount: response.results.last_page == 1 ? 0 :  response.results.last_page});
        } 
        else{
          setProjectNotfound(true);
        }
        setLoading(false);
      });  
      setFirstLoad(false); 
    
  },[project_id, searchStatus, searchDate, cPage, searchType]);

  useEffect( () => {
    if(!firstLoad){
      const timer = setTimeout(() => {
        let params: any = {};
        params.project_id = project_id;
        
        params.keyword = searchKeyword.trim();
        
        if(searchStatus!= ''){
          params.status = searchStatus;
        }
        if(!resetFilter && searchDate){
          params.date = dateFormat(searchDate, "yyyy-mm-dd");
        }
        params.type = searchType;
        
        params.page = cPage;
        setLoading(true); 
        AuthGetData('/project-contracts', params).then((response) => {
          if(response.success ){ 
            setContracts(response.results.data);
            setPaginationInfo({pageCount: response.results.last_page == 1 ? 0 :  response.results.last_page});
          } 
          else{
            setProjectNotfound(true);
          }
          setLoading(false);
        });  
        
      }, 500)

      return () => clearTimeout(timer)
    }
  },[searchKeyword]);

  useEffect( () => { 
    let params: any = {};
    params.project_id = project_id;
    if(searchKeyword.trim() != ''){
      params.keyword = searchKeyword.trim();
    }
    if(searchStatus!= ''){
      params.status = searchStatus;
    }
    if(!resetFilter && searchDate){
      params.date = dateFormat(searchDate, "yyyy-mm-dd");
    }
    AuthGetData('/project-payments', params).then((response) => {
      if(response.success ){  
        setPayables(response.results.data.filter((payment: any) => payment.type === 'payable'));
        setReceivables(response.results.data.filter((payment: any) => payment.type === 'receivable'));
      } 
       
    });  
  },[contracts]);
  const addContract = () => {
    
  };  
  const showProjectPayments = (contract_id: Number) => {
    $('.contract-payment-'+contract_id).toggle();
    $('.contract-'+contract_id).toggleClass('show');
  };  

  const viewEditPayment = (contract_id: Number, payment_id:number) => {
    const ct: Contract[]  = contracts.filter((contract:Contract) => contract.id == contract_id); 
    const payment =  ct[0].payments.filter((payment) => payment.id == payment_id); 
    console.log(payment);
    setViewingPayment(payment[0]); 
    setEditingContractID(contract_id);

    setValue("item_id",  payment[0].item_id );
    setValue("trade_id",  payment[0].trade_id );
    setValue("item_id",  payment[0].item_id );
    setValue("cheque_number",  payment[0].cheque_number );
    setValue("amount",  payment[0].amount);
    setValue("percent",  payment[0].percent);
    setValue("remarks",  payment[0].remarks);
    
    $('.showPaymentModal').trigger('click');
  }
  const setViewingPaymentData = (data:any[]) => {
    let res:any = {...viewingPayment};
    data.forEach(function(field, index){
      
      res[field.name] = field.value; 
    }) 
    setViewingPayment(res);
    console.log(viewingPayment);
  }
  /*
  const setViewingPaymentData = (prop_name: string, value: string | number) => {
    let res:any = {...viewingPayment};
    res[prop_name] = value; 
    setViewingPayment(res);
    console.log(viewingPayment);
  }
  */
  const updatePayment = async (FormData: any) => {
 
    setUpdatingPayment(true);
    AuthPostData('/update-payments', {payments:[viewingPayment], paidIDs: []}).then((response) => {
      if(response.success ){   
        const index = contracts.findIndex((contract: Contract) => contract.id == editingContractID);
        const payment_index = contracts[index].payments.findIndex((payment: Payment) => payment.id == viewingPayment?.id);
      
        contracts[index].payments[payment_index] = viewingPayment? viewingPayment : contracts[index].payments[payment_index];
        
        toast.success('Updated', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }  
      $('.close-modal').trigger('click');
      setUpdatingPayment(false);
    });
    
  }
  const deleteInvoice = () => {
    const confirmBox = window.confirm(
      "Do you really want to delete this invoice?"
    )
    if (confirmBox === true) {
      setUpdatingPayment(true);
      AuthPostData('/delete-payment', {uid: window.localStorage.getItem("uid"), payment_id: viewingPayment?.id}).then((response) => {
        if(response.success ){   
          const index = contracts.findIndex((contract: Contract) => contract.id == editingContractID);
          const payment_index = contracts[index].payments.findIndex((payment: Payment) => payment.id == viewingPayment?.id);
          contracts[index].payments.splice(payment_index, 1);
          toast.success('Deleted!', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }  
        else{
          toast.error(response.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }
        setUpdatingPayment(false); 
      });  
      $('.close-modal').trigger('click');
    }
  }
  const handlePageClick = (event:any) => {
    let selected_page = event.selected + 1;
    navigate('/project/'+id+'/page/'+selected_page);  
  };

  const exportData = () => {

    const url = process.env.REACT_APP_API_ENDPOINT + '/project-contracts-export?project_id='+project_id+'&date='+exportRange;
       
    const accessToken = window.localStorage.getItem("token");
    fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer '+accessToken, 
        'Content-Type': 'application/json'
      }, 
    }) 
    .then((response) => response.blob())
    .then((myBlob) => {
      // Creating new object of PDF file
      const fileURL = window.URL.createObjectURL(myBlob);
        
      // Setting various property values
      let alink = document.createElement("a");
      alink.href = fileURL;
      alink.download = "invoices-pr"+project_id+".xlsx";
      alink.click();
       
    });
  }
  const onExportDateChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setExportRange(dateFormat(startDate, "yyyy-mm-dd")+':'+dateFormat(endDate, "yyyy-mm-dd"));
  };

  if(projectNotfound){
    return (
      <div className="pt-3"><div className='alert alert-danger'>Project Not Found!</div></div>
    );
  }
  return ( 
    <div>
      <ToastContainer />  
      <PageTitle title="Contracts" />  
      <div className="project-contract-filter mb-3">
        <div className="row">
          <div className="col-md-auto col-1 d-flex align-items-center bg-filter border-right-0  mb-2 mb-md-0"><i className="fas fa-filter fa-fw filter-icon"></i></div>
          <div className="col-md-auto col-11 d-flex align-items-center bg-filter border-right-0 mb-2 mb-md-0 keyword-wrap">
            <input type="text" className="keyword form-control" placeholder="Enter Keyword..." value={searchKeyword}  onChange={e => { setSearchKeyword(e.target.value);setResetFilter(false)}}  /> 
          </div>
          <div className="col-md-auto col-6 col-sm-4   d-flex align-items-center filter-date bg-filter border-right-0 justify-content-center">
             
          <DatePicker className="form-control"
            showIcon
                selected={searchDate}
                onChange={(date:any) => {setSearchDate(date);setResetFilter(false)}}
                dateFormat="dd.MM.yyyy"
              />
          </div>
          <div className="col-md-auto col-6 col-sm-4 d-flex align-items-center bg-filter project-status justify-content-center">
            <select className="cursor-pointer custom-select" value={searchStatus} onChange={(e)=> {setSearchStatus( e.target.value); setResetFilter(false)}}>
              <option value="">Status</option>
              <option value="Open">Open</option>
              <option value="Pending">Pending</option>
              <option value="Fulfilled">Fulfilled</option>
            </select>
          </div>
          <div className="col-md-auto col-auto col-sm-4 mt-2 mt-sm-0 border d-flex align-items-center filter-action bg-filter text-red justify-content-center">
            <span className="cursor-pointer">
            <span><i className="fas fa-sync mr-2"></i></span>
            <span className="filter-date-value" onClick={() => { setSearchKeyword(''); setSearchStatus(''); setSearchDate(  ''); setResetFilter(true); }}>Reset Filter</span>
            </span>
          </div>
        </div> 
      </div>


      <div className="row mb-5">
         
        <div className="col-lg-4 col-6 pb-3">
          <div className={"small-box bg-white info-box filter-type " + (searchType === 'summary' ? ' disabled active' : 'cursor-pointer')} onClick={()=> setSearchType('summary')}>
            <div className="inner">
              <p className="heading">Summary</p>
              <h3>{contracts.length} Contracts</h3>
              <div className="info-details">{contracts.filter(item => item.status === 'Settled').length} Fulfilled<br />{contracts.filter(item => item.status === 'Pending').length} Pending</div>  
            </div> 
          </div>
        </div>
        <div className="col-lg-4 col-6 pb-3">
          <div className={"small-box bg-white info-box filter-type " + (searchType === 'payable' ? ' disabled active' : 'cursor-pointer')} onClick={()=> setSearchType('payable')}>
            <div className="inner">
              <p className="heading">Payables</p>
              <h3>{payables.length} Payables</h3>
              <div className="info-details">{payables.filter( (payment:Payment) => payment.status !== 'Settled').length} Pending<br />    
              {payables.filter( (payment:Payment) => payment.status === 'Settled').length} Paid in Ful</div> 
              
            </div> 
          </div>
        </div>
        <div className="col-lg-4 col-6 pb-3">
          <div className={"small-box bg-white info-box filter-type " + (searchType === 'receivable' ? ' disabled active' : 'cursor-pointer')} onClick={()=> setSearchType('receivable')}>
            <div className="inner">
              <p className="heading">Receivables</p>
              <h3>{receivables.length} Receivables</h3>
              <div className="info-details">{receivables.filter( (payment:Payment) => payment.status !== 'Settled').length} Pending<br />
              {receivables.filter( (payment:Payment) => payment.status === 'Settled').length} Paid in Ful</div> 
              
            </div> 
          </div>
        </div>
         
      </div>


      <div className="py-2 mb-3"><Link className="btn btn-primary" to={'/add-contract?project_id='+project_id}>Add Contract</Link></div>
      <div className="payments-list project-payments-list list-data">
        <h3 className="section-heading">Summary of Contracts - Project: {projectInfo.project_name}</h3>
        <div className="mb-2 d-flex align-items-center">
          <button className="action-btn" onClick={exportData}>Export Data</button>
          <DatePicker
            selected={startDate}
            onChange={ onExportDateChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            customInput={<i className="fas fa-fw  fa-regular fa-calendar" style={{fontSize: '22px', cursor: 'pointer'}}></i>} 
          />
        </div>
        <div className="table-container">
          <table className ="table table-bordered table-hover border-0 data-tbl">
            <thead>
              <tr>
                <th className="toggle-col"></th>
                <th className="id-col">ID</th>
                <th>Vendor name</th>
                <th>Invoice No.</th>
                <th>Create Date</th>
                <th>Status</th> 
                <th>Amount</th>
                <th>Net Due</th>
                <th>Setted On</th>
                <th>Cheque No</th>
                <th style={{"width": "100px"}}>Action</th>
              </tr>
            </thead>
            <tbody>
              { (contracts.length < 1 && !loading ) ? <tr><td colSpan={11}>No Contract!</td></tr> : ( 
                loading ? <tr><td colSpan={11}><Loader /></td></tr> :  
                  (
                     
                     
                            contracts.map((contract:Contract, index) =>  {
                              
                              let year = dateFormat(contract.created_at, "yyyy") ;
                              
                              let showYear = false;
                              if(index === 0){
                                showYear = true;
                              }
                              else{
                                 
                                if(year !== dateFormat(contracts[index-1].created_at, "yyyy")) {
                                  showYear = true;
                                }
                                 
                              }
                              return  (
                              
                              <>
                                {showYear && <tr key={"ct-y-"+index}><td colSpan={11} className="year">{year}</td></tr>}
                                <tr key={"ct"+index} className={"contract contract-"+contract.id} data-id={contract.id}>
                                  <td className="toggle-col"><span className="payment-toggle" onClick={() => { showProjectPayments( contract.id) } }><i className="fas fa-chevron-right fa-fw"></i></span></td>
                                  <td className="id-col">{contract.id}</td>
                                  <td>{contract.vendor_name}</td>
                                  <td>{contract.contract_no}</td>
                                  <td>{dateFormat(contract.created_at, "dd.mm.yyyy") }</td>
                                  <td><span className={"status " + ( ['Settled','Fulfilled'].includes( contract.status)? 'bg-green' :  ( contract.status == 'Open' ? 'bg-red' : 'bg-yellow' ) )   }>{contract.status}</span></td>
                                  <td><NumericFormat thousandSeparator="." decimalSeparator=","  displayType="text" prefix={'HK$'}  value={contract.amount} /></td>
                                  <td><NumericFormat thousandSeparator="." decimalSeparator=","  displayType="text" prefix={'HK$'}  value={0} /></td>
                                  <td>-</td>
                                  <td>-</td>
                                  <td><Link to={"/contract?id=" + contract.id} className="action-btn">Details</Link> <Link to={"/edit-contract?id=" + contract.id} className="action-btn">Edit</Link></td>
                                </tr>
                                {
                                contract.payments?.map((payment, id) => (
                                  <tr key={"pm"+id} className={"contract-payment-"+contract.id} style={{'display': 'none'}}>
                                    <td className="toggle-col"></td>
                                    <td className="id-col"></td>
                                    <td>{contract.vendor_name}</td>
                                    <td>{payment.invoice_no}</td>
                                    <td>{dateFormat(payment.created_at, "dd.mm.yyyy") }</td>
                                    <td><span className={"status " + ( ['Settled in Full','Settled'].includes( payment.status)? 'bg-green' :  ( payment.status == 'Unpaid' ? 'bg-red' : 'bg-yellow' ) )   }>{payment.status}</span></td>
                                    <td><NumericFormat thousandSeparator="." decimalSeparator=","  displayType="text" prefix={'HK$'}  value={payment.amount} /></td>
                                    <td><NumericFormat thousandSeparator="." decimalSeparator=","  displayType="text" prefix={'HK$'}  value={0} /></td>
                                    <td>-</td>
                                    <td>{payment.cheque_number}</td>
                                    <td><button  className="action-btn" onClick={()=> viewEditPayment(contract.id , payment.id) }>View/Edit Payment</button></td>
                                  </tr>
                                ))
                                }
                              </> 
                              )
                            })
                     
                ) 
              )
            }
            </tbody>
              
          </table>
        </div>      
        <div className="pagi text-center ">
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick} 
            initialPage={cPage-1} 
            disableInitialCallback = {true}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={paginationInfo.pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            pageClassName='page-item'
            className="pagination pagination-sm d-inline-flex"
            pageLinkClassName="page-link"
            previousClassName="page-link"
            nextClassName="page-link"
          />     
        </div> 
      </div>
      <button className="d-none showPaymentModal" data-toggle="modal" data-target="#viewPaymentModal">InvoicesModal</button>
      {/* Lightbox */}
      <div className="modal fade" id="viewPaymentModal" aria-labelledby="viewPaymentModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
          <form onSubmit={handleSubmit(updatePayment)}  className={loading ? 'make-payment-form loading' : 'make-payment-form'}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="viewPaymentModalLabel">Payment #{viewingPayment?.invoice_no}</h5>
                <div className=''>Belongs to Contract "{viewingPayment?.vendor_name}": <Link to="/contract?id=2" className="action-btn goto-contract" onClick={()=>{
                      $('.close-modal').trigger('click'); 
                    }}>View contract</Link>
                </div>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>Details</p>
                <div className="row justify-content-between align-items-center mb-3">
                  <div className="col-sm-6 col-12">
                    <div className="row align-items-center">
                      <div className="col-sm-4 col-4">Created on:</div> 
                      <div className="col-sm-7 col-8">{dateFormat(viewingPayment?.created_at, "dd.mm.yyyy")}</div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-12">
                    <div className="row align-items-center">
                      <div className="col-sm-4 offset-sm-1 col-4">Settled on:</div> 
                      <div className="col-sm-7  col-8">12.12.2020</div>
                    </div>
                  </div>
                  
                  
                </div>

                <div className="row justify-content-between align-items-center mb-3">
                  {/*
                  <div className="col-sm-6 col-12">
                    <div className="row align-items-center mb-3 mb-sm-0">
                      <div className="col-sm-4 col-4">Amount:</div>
                      <div className="col-sm-7 col-8"><input type="text" className="form-control input-text" value={viewingPayment?.amount? viewingPayment?.amount : ''} /></div>
                    </div>
                      </div> */}
                  <div className="col-sm-6 col-12">
                    <div className="row align-items-center mb-3 mb-sm-0">
                      <div className="col-sm-4  col-4">Responsible:</div>
                      <div className="col-sm-7 col-8">
                        <Select options={responsibles} value={{value: viewingPayment?.responsible_user_id, label: viewingPayment?.responsible? viewingPayment.responsible : 'Select responsible' }} onChange={(option, actionMeta) => {
                        let res:any = {...viewingPayment};
                        res.responsible_user_id = option?.value;
                        res.responsible = option?.label;
                        setViewingPayment(res);
                        }}></Select>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-12">
                    <div className="row align-items-center">
                      <div className="col-sm-4 offset-sm-1 col-4">Invoice No.</div> 
                      <div className="col-sm-7 col-8"><input type="text" className="form-control input-text" value={viewingPayment?.invoice_no ? viewingPayment?.invoice_no : ''} onChange={(e) =>setViewingPaymentData([{name:'invoice_no', value:e.target.value}])} readOnly /></div>
                    </div>
                  </div>
                </div>

                <div className="row justify-content-between align-items-center mb-3">
                  <div className="col-sm-6 col-12">
                    <div className="row align-items-center mb-3 mb-sm-0">
                      <div className="col-sm-4 col-4">Cheque No.</div>
                      <div className="col-sm-7 col-8">
                        <input type="text" {...register("cheque_number", { required: "This field is required", pattern: {value: /^\d+$/, message: 'Not valid!' } })} className="form-control input-text" value={viewingPayment?.cheque_number ? viewingPayment.cheque_number: ''} onChange={(e) => setViewingPaymentData([{name:'cheque_number', value:e.target.value}])} />
                        {errors.cheque_number && <div className='w-100'><span className="error text-red">{errors.cheque_number?.message?.toString()}</span></div>} 
                      </div>
                    </div>
                  </div>
                  
                </div>
                <hr />
                <p>Description</p>
                <div className="row justify-content-between align-items-center mb-3">
                  { viewingPayment?.type !== 'receivable' && (
                  <>
                  <div className="col-sm-6 col-12">
                    <div className="row align-items-center mb-3 mb-sm-0">
                      <div className="col-sm-4 col-4">Percent:</div>
                      <div className="col-sm-7 col-8">
                        <input type="text" {...register("percent",  { required: "This field is required", min: {value: 1, message: 'Number from 1 to 100' }, max: {value: 100, message: 'Number from 1 to 100' },  pattern: {value: /^\d+$/, message: 'Not valid!' } })}  className="form-control input-text" value={viewingPayment?.percent ? viewingPayment.percent: ''} onChange={(e) =>setViewingPaymentData([{name:'percent', value: parseInt( e.target.value)}])} />
                        {errors.percent && <div className='w-100'><span className="error text-red">{errors.percent?.message?.toString()}</span></div>}
                      </div>
                    </div>
                  </div>
                  </>
                  )}
                  <div className="col-sm-6 col-12">
                    <div className="row align-items-center">
                      <div className={"col-sm-4 col-4" + (viewingPayment?.type !== 'receivable' ? ' offset-sm-1' : '')} >Amount:</div> 
                      <div className="col-sm-7 col-8">
                        <input type="text" {...register("amount", { required: "This field is required", pattern: {value: /^(0|[1-9]\d*)(\.\d+)?$/, message: 'Not valid!' } })} className="form-control input-text" value={viewingPayment?.amount? viewingPayment?.amount : ''} onChange={(e) =>setViewingPaymentData([{name:'amount', value:e.target.value}])} />
                        {errors.amount && <div className='w-100'><span className="error text-red">{errors.amount?.message?.toString()}</span></div>} 
                      </div>
                    </div>
                  </div> 
                </div>
                {viewingPayment?.type !== 'receivable' && (
                <> 
                <div className="row justify-content-between align-items-center mb-3">
                  <div className="col-sm-6 col-12">
                    <div className="row align-items-center mb-3 mb-sm-0">
                      <div className="col-sm-4 col-4">Trade:</div>
                      <div className="col-sm-7 col-8">  
                      <Controller
                        name={"trade_id"}
                        control={control}
                        rules={{ required: 'Please select Trade' }} 
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Select options={trades}   value={{value: viewingPayment?.trade_id, label: viewingPayment?.trade_name? viewingPayment.trade_name : 'Select Trade'  }} onChange={(option, actionMeta) => {
                          console.log(option?.value);
                          setValue("trade_id", option?.value); 
                          
                          setViewingPaymentData([{name:'trade_name', value:option?.label ? option.label : 0},{name:'trade_id', value:option?.value ? option.value : 0}]);

                          
                          
                          
                        }} ></Select> 
                        )}
                        />  
                        {errors.trade_id && <div className='w-100'><span className="error text-red">{errors.trade_id?.message?.toString()}</span></div>}  
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-12">
                    <div className="row align-items-center">
                      <div className="col-sm-4 offset-sm-1 col-4">Item:</div> 
                      <div className="col-sm-7 col-8">
                      <Controller
                        name={"item_id"}
                        control={control}
                        rules={{ required: 'Please select Trade' }} 
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Select options={items} value={{value: viewingPayment?.item_id, label: viewingPayment?.item_name? viewingPayment.item_name : 'Select Trade'  }} onChange={(option, actionMeta) => {
                          setValue("item_id", option?.value); 
                          setViewingPaymentData([{name:'item_name', value:option?.label ? option.label : 0},{name:'item_id', value:option?.value ? option.value : 0}]);
                          
                        }} ></Select> 
                        )}
                        />  
                        {errors.item_id && <div className='w-100'><span className="error text-red">{errors.item_id?.message?.toString()}</span></div>}  
                      </div>
                    </div>
                  </div>
                </div>
                </>
                )}
                <div className="row align-items-center mb-3">
                  <div className="col-sm-2 col-4">Description:</div>
                  <div className="col-sm-10 col-8"><input type="text" className="form-control input-text" value={viewingPayment?.remarks ? viewingPayment.remarks : ''} onChange={(e) =>setViewingPaymentData([{name:'remarks', value:e.target.value}] )} /></div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="row w-100">
                  <div className="col-6">
                    <button type="submit" className="btn btn-primary" disabled={updatingPayment}>Save</button>
                    <button type="button" className="btn bg-red ml-2" onClick={deleteInvoice} >Delete invoice</button>
                  </div>
                  <div className="col-6 text-right"><button type="button" className="btn btn-secondary close-modal" data-dismiss="modal">Close</button></div>
                </div> 
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* Lightbox */}
      
    </div>
  
 
  );
}

export default Project;
